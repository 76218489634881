import Joi, { CustomHelpers } from "joi";
import { range } from "lodash";
import {
  BusinessEntityType,
  Coordinates,
  Document,
  NewBusinessEntityInputWithType,
  OpeningSchedule,
  StorageFacilityInput,
} from "../../../graphql/generated";
import { isBefore, parse } from "date-fns";
import customFieldSchema from "../../extensions/CustomFieldsForm/customFieldsSchema";

const textualAddressSchema = Joi.object<
  NewBusinessEntityInputWithType["billingAddress"]
>({
  line1: Joi.string().required().label("Address Line 1"),
  line2: Joi.string().allow("", null).label("Address Line 2"),
  city: Joi.string().required().label("City"),
  state: Joi.string().required().label("State"),
  postalCode: Joi.string().required().label("Postal Code"),
  country: Joi.string().required().label("Country"),
  googlePlaceId: Joi.string().allow("", null).label("Google Place ID"),
});

const physicalAddressSchema = Joi.object<
  NewBusinessEntityInputWithType["address"]
>({
  label: Joi.string().required().label("Address Label"),
  coordinates: Joi.object<Coordinates>({
    latitude: Joi.number().required(),
    longitude: Joi.number().required(),
  })
    .required()
    .label("Coordinates"),
  line1: Joi.string().allow("", null).label("Address Line 1"),
  line2: Joi.string().allow("", null).label("Address Line 2"),
  postalCode: Joi.string().allow("", null).label("Postal Code"),
  city: Joi.string().allow("", null).label("City"),
  state: Joi.string().allow("", null).label("State"),
  country: Joi.string().allow("", null).label("Country"),
  googlePlaceId: Joi.string().allow("", null).label("Google Place ID"),
}).label("Physical Address");

const physicalAddressHardValidationSchema = physicalAddressSchema
  .fork("line1", () => Joi.string().required().label("Address Line 1"))
  .fork("postalCode", () => Joi.string().required().label("Postal Code"))
  .fork("city", () => Joi.string().required().label("City"))
  .fork("state", () => Joi.string().required().label("State"))
  .fork("country", () => Joi.string().required().label("Country"));

const hasOneFieldFilled: Joi.CustomValidator<
  NewBusinessEntityInputWithType["contact"]
> = (
  value: NewBusinessEntityInputWithType["contact"],
  helpers: CustomHelpers<NewBusinessEntityInputWithType["contact"]>
) => {
  const { firstname, lastname, phoneNumber, email } = value || {};
  if (!(firstname || lastname || phoneNumber || email)) {
    return helpers.error("any.custom", {
      error: new Error("at least one field must be filled"),
    });
  }
  return value;
};

const isClosingTimeAfterOpeningTime: Joi.CustomValidator<
  NonNullable<NewBusinessEntityInputWithType["openingSchedules"]>[0]
> = (
  value: NonNullable<NewBusinessEntityInputWithType["openingSchedules"]>[0],
  helpers: CustomHelpers<OpeningSchedule>
) => {
  const { openingTime, closingTime } = value;
  const openingTimeParsed = parse(openingTime, "HH:mm", new Date());
  const closingTimeParsed = parse(closingTime, "HH:mm", new Date());
  if (isBefore(closingTimeParsed, openingTimeParsed)) {
    return helpers.error("any.custom", {
      error: new Error("closing time must be after opening time"),
    });
  }
  return value;
};

const contactSchema = Joi.object<NewBusinessEntityInputWithType["contact"]>({
  firstname: Joi.string()
    .allow("", null)
    .min(2)
    .max(100)
    .label("Contact first name"),
  lastname: Joi.string()
    .allow("", null)
    .min(2)
    .max(100)
    .label("Contact last name"),
  email: Joi.string()
    .allow("", null)
    .email({
      tlds: false,
    })
    .label("Contact email"),
  phoneNumber: Joi.string().allow("", null).label("Contact phone number"),
  includeInInvoicing: Joi.boolean().allow(null).label("Include in invoicing"),
  title: Joi.string().allow("", null).label("Contact title"),
  url: Joi.string().allow("", null).label("Contact URL"),
  faxNumber: Joi.string().allow("", null).label("Contact fax number"),
  extensionNumber: Joi.string()
    .allow("", null)
    .label("Contact extension number"),
}).label("Contact");

const goodInventoryItemSchema = Joi.object<
  Exclude<
    NewBusinessEntityInputWithType["goodInventoryItems"],
    undefined | null
  >[0]
>({
  _id: Joi.string().allow(null),
  label: Joi.string().max(100).required().label("Good label"),
  weight: Joi.number().min(0).allow(null).label("Good weight"),
}).label("Good inventory item");

const documentSchema = Joi.object<Document>({
  _id: Joi.string().allow(null),
  name: Joi.string().required().label("Document name"),
  url: Joi.string().required().label("Document URL"),
});

const businessEntitySchema = Joi.object<NewBusinessEntityInputWithType>({
  name: Joi.string().min(3).max(50).required().label("Name"),
  type: Joi.string().required().label("Type"),
  code: Joi.string().min(3).max(50).label("Business code").allow(null, ""),
  mcNumber: Joi.string().label("MC Number").allow(null, ""),
  standardCarrierAlphaCode: Joi.string().label("SCAC").allow(null, ""),
  dotNumber: Joi.string().label("DOT Number").allow(null, ""),
  federalId: Joi.string().label("Federal ID").allow(null, ""),
  payToProfileId: Joi.string().label("Pay to profile").allow(null, ""),
  creditTerms: Joi.number().allow(null).min(1).label("Credit terms"),
  referenceNumberTypes: Joi.array().allow(null),
  additionalTypes: Joi.array().items(Joi.string()).allow(null),
  trailerTypes: Joi.array().items(Joi.string()).allow(null),
  contact: contactSchema.label("Contact details").allow(null),
  address: Joi.alternatives().conditional(Joi.ref("type"), {
    is: Joi.string().valid(BusinessEntityType.PayToProfile),
    then: physicalAddressSchema.allow(null),
    otherwise: physicalAddressSchema.required(),
  }),
  billingAddress: textualAddressSchema.allow(null).label("Billing Address"),
  billingEmail: Joi.string()
    .allow("", null)
    .email({
      tlds: false,
    })
    .label("Billing email"),
  remitEmail: Joi.string()
    .allow("", null)
    .email({
      tlds: false,
    })
    .label("Remit email"),
  remitCompanyName: Joi.string().allow("", null).label("Remit company name"),
  billingTermsDay: Joi.number().allow(null).min(1).label("Billing terms"),
  remitAddress: textualAddressSchema.allow(null).label("Remit address"),
  openingSchedules: Joi.array()
    .items(
      Joi.object<OpeningSchedule>({
        days: Joi.array()
          .min(1)
          .required()
          .items(Joi.number().valid(...range(0, 7)))
          .label("Days"),
        openingTime: Joi.string()
          .required()
          .regex(/^[0-9][0-9]:[0-9][0-9]$/)
          .label("Opening time"),
        closingTime: Joi.string()
          .required()
          .regex(/^[0-9][0-9]:[0-9][0-9]$/)
          .label("Closing time"),
      }).custom(
        isClosingTimeAfterOpeningTime,
        "closing time after opening time"
      )
    )
    .label("Operating hours"),

  additionalContacts: Joi.array()
    .items(contactSchema.custom(hasOneFieldFilled, "one field filled at least"))
    .label("Additional contacts")
    .allow(null),
  goodInventoryItems: Joi.array()
    .items(goodInventoryItemSchema)
    .label("Good inventory")
    .allow(null),

  storageFacilities: Joi.array()
    .items(
      Joi.object<StorageFacilityInput>({
        commodityId: Joi.string().required().label("Commodity"),
        identifier: Joi.string().required().label("Identifier"),
        capacity: Joi.number().required().min(0).label("Capacity"),
        unit: Joi.string().required().label("Unit"),
        safeFillLevel: Joi.number()
          .allow(null)
          .min(0)
          .max(Joi.ref("capacity"))
          .label("Safe fill level"),
        safeFillPercentage: Joi.number().allow(null).label("Safe fill %"),
        shutDownLevel: Joi.number()
          .allow(null)
          .min(0)
          .max(Joi.ref("capacity"))
          .max(Joi.ref("safeFillLevel"))
          .message("Shut down level must be less than safe fill level")
          .label("Shut down level"),
        shutDownPercentage: Joi.number().allow(null).label("Shut down %"),
        defaultPinCode: Joi.string().allow(null).label("Default pin code"),
        defaultSupplierId: Joi.string().allow(null).label("Default supplier"),
      })
    )
    .label("Storage facilities")
    .allow(null),

  parentBusinessEntityId: Joi.string().allow(null).label("Parent customer"),
  defaultShipperId: Joi.string().allow(null).label("Default shipper"),
  accessToken: Joi.string().allow(null).label("Access token"),
  documents: Joi.array().items(documentSchema).label("Documents").allow(null),
  tags: Joi.array().items(Joi.string()).label("Tags").allow(null),
  customFields: Joi.array()
    .items(customFieldSchema)
    .label("Custom Fields")
    .allow(null),
  groupIds: Joi.array().items(Joi.string()).label("Group IDs").allow(null),
  status: Joi.string().allow(null),
});

export const liquidBusinessEntitySchema = businessEntitySchema
  .fork("parentBusinessEntityId", () =>
    Joi.alternatives().conditional(Joi.ref("type"), {
      is: Joi.string().valid(BusinessEntityType.Receiver),
      then: Joi.string().required().label("Parent customer"),
      otherwise: Joi.string().allow(null).label("Parent customer"),
    })
  )
  .fork("defaultShipperId", () =>
    Joi.alternatives().conditional(Joi.ref("type"), {
      is: Joi.string().valid(BusinessEntityType.Receiver),
      then: Joi.string().required().label("Default shipper"),
      otherwise: Joi.string().allow(null).label("Default shipper"),
    })
  )
  .fork("trailerTypes", () =>
    Joi.alternatives().conditional(Joi.ref("type"), {
      is: Joi.string().valid(BusinessEntityType.Carrier),
      then: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Carrier types"),
      otherwise: Joi.array().items(Joi.string()).allow(null),
    })
  )
  .fork("address", () =>
    physicalAddressHardValidationSchema.required().label("Physical Address")
  );

export default businessEntitySchema;
