import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  NewBusinessEntityInputWithType,
  CustomFieldContext,
} from "../../../../graphql/generated";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SupplierContractsQuery } from "../../../../graphql/generated";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../../common/SmartLynksTable/SmartLynksTable";
import { TableField } from "../../../common/LynksTable/LynksTable";
import SupplierContractForm from "../../SupplierContractForm";
import { capitalize } from "lodash";
import { formatDate } from "../../../../utils/labels/formatDateTime";
import { Edit } from "@mui/icons-material";
// import ExcelImporter from "../../../common/ExcelImporter";

type SupplierContractsListItem =
  SupplierContractsQuery["supplierContracts"]["data"][0];

export default function ContractsList({
  query,
  onBulkCreate,
  deleteMutation,
  supplierId,
}: {
  query: QueryFn<SupplierContractsListItem, "supplierContracts", never>;
  deleteMutation: DeleteMutation;
  onBulkCreate: (data: NewBusinessEntityInputWithType[]) => Promise<any>;
  supplierId: string;
}) {
  const { t } = useTranslation(["business", "assets", "common"]);
  const [isSupplierContractFormOpen, setIsSupplierContractFormOpen] =
    useState(false);
  const [currentSupplierContract, setCurrentSupplierContract] =
    useState<SupplierContractsListItem | undefined>(undefined);

  const fields: TableField<SupplierContractsListItem>[] = useMemo(
    () => [
      {
        value: "contractNumber",
        label: capitalize(
          t("business:supplier.contract.contractNumber", "Contract Number")
        ),
        type: "string",
      },
      {
        value: (contract) => contract.customer?.name,
        label: capitalize(t("business:customer.one", "Customer")),
        type: "string",
      },
      {
        value: (contract) => contract.shipper?.name,
        label: capitalize(t("business:shipper.one", "Shipper")),
        type: "string",
      },
      {
        value: (contract) => contract.goodProfile?.label,
        label: capitalize(t("assets:commodity.one", "Commdotity")),
        type: "string",
      },
      {
        value: (contract) => (
          <Tooltip
            title={
              <Box>
                <Typography>
                  {t("common:daily", "Daily")}:{" "}
                  {allocationLabel(
                    contract.allocation.dailyQuantity,
                    contract.usedAllocation?.dailyQuantity
                  )}
                </Typography>
                <Typography>
                  {t("common:weekly", "Weekly")}:{" "}
                  {allocationLabel(
                    contract.allocation.weeklyQuantity,
                    contract.usedAllocation?.weeklyQuantity
                  )}
                </Typography>
                <Typography>
                  {t("common:monthly", "Monthly")}:{" "}
                  {allocationLabel(
                    contract.allocation.monthlyQuantity,
                    contract.usedAllocation?.monthlyQuantity
                  )}
                </Typography>
              </Box>
            }
          >
            <Typography sx={{ textDecorationLine: "underline" }}>
              {allocationLabel(
                contract.allocation.totalQuantity,
                contract.usedAllocation?.totalQuantity
              )}
            </Typography>
          </Tooltip>
        ),
        label: capitalize(
          t("business:supplier.contract.totalAllocation", "Allocation")
        ),
        type: "string",
      },
      {
        value: (contract) => formatDate(contract.startDate),
        label: t("common:startDate", "Start Date"),
        type: "string",
      },
      {
        value: (contract) => formatDate(contract.endDate),
        label: t("common:endDate", "End Date"),
        type: "string",
      },
      {
        value: "unitPrice",
        label: t("business:supplier.contract.unitPrice", "Price/Unit"),
        type: "number",
      },
    ],
    [t]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "flex-end",
          mb: 2,
        }}
      >
        {/* <ExcelImporter
          mapping={suppliersMapping}
          schema={businessEntitySchema}
          onBulkCreate={onBulkCreate}
        /> */}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            alignSelf: "end",
            mb: 2,
          }}
          id="new-contract-button"
          aria-label="New Contract"
          onClick={() => setIsSupplierContractFormOpen(true)}
        >
          {t("business:supplier.contract.new", "New Contract")}
        </Button>
      </Box>

      <Box
        sx={{
          height: 700,
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="supplierContracts"
          fields={fields}
          customFieldContext={[CustomFieldContext.Supplier]}
          additionalQueryVariables={{
            supplierId,
          }}
          actions={[
            {
              icon: <Edit />,
              onClick: (record) => {
                setIsSupplierContractFormOpen(true);
                setCurrentSupplierContract(record);
              },
              tooltip: t("common:list.edit", "Edit"),
            },
          ]}
          deleteMutation={deleteMutation}
          id="supplier-contracts-list"
        />
      </Box>

      <Dialog
        open={isSupplierContractFormOpen}
        onClose={() => {
          setIsSupplierContractFormOpen(false);
          setTimeout(() => {
            setCurrentSupplierContract(undefined);
          }, 500);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {currentSupplierContract
            ? t("business:supplier.contract.edit", "Edit contract")
            : t("business:supplier.contract.new", "Add contract")}
        </DialogTitle>
        <DialogContent>
          <SupplierContractForm
            supplierContractId={currentSupplierContract?._id}
            onSave={() => {
              setIsSupplierContractFormOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const allocationLabel = (
  allocatedQty: number | null | undefined,
  usedQty: number | null | undefined
) => {
  if (!allocatedQty) {
    return "N/A";
  }
  return `${allocatedQty} ${usedQty ? `(${usedQty} Used)` : ""}`;
};
