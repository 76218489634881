import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  GetBusinessEntityDetailsQuery,
  GoodDistributionInput,
  GoodInput,
  GoodProfile,
  GoodUnits,
  LoadType,
  NoteAccessLevel,
  ShipmentCommodityType,
  ShipmentLocationType,
  ShipmentNoteInput,
  TrailerType,
} from "../../../graphql/generated";
import { v4 as uuid } from "uuid";
import {
  ShippedGoodsPayload,
  NewShipmentInputData,
  ReceivedGoodsPayload,
  ShipmentLocationInputData,
  ShipmentDocumentInputData,
  PredefinedRecurrence,
  BestBuyOption,
} from "../Types";
import flatMap from "lodash/flatMap";
import { poundsToKg } from "../../../utils/conversion/weight";
import LocaleProvider from "../../../providers/LocaleProvider";

export const defaultReceiver: ShipmentLocationInputData = {
  _id: uuid(),
  location: {
    longitude: 0,
    latitude: 0,
  },
  shippedGoods: [],
  locationType: ShipmentLocationType.DropOff,
  receiver: "",
  receivedGoods: [],
  timeWindows: [],
};

export const defaultGood: GoodInput = {
  _id: uuid(),
  label: "",
  quantity: 1,
  weight: LocaleProvider.getWeightUnit() === "lb" ? poundsToKg(1) : 1,
};

export const defaultShipper: ShipmentLocationInputData = {
  _id: uuid(),
  location: {
    longitude: 0,
    latitude: 0,
  },
  shippedGoods: [],
  locationType: ShipmentLocationType.Pickup,
  receiver: "",
  shipper: "",
  receivedGoods: [],
  timeWindows: [],
};

interface ShipmentState {
  shipment: NewShipmentInputData;
  isCommodityDriven: boolean;
  goodProfiles: GoodProfile[];
  storageFacilitiesByReceiverId: Record<
    string,
    GetBusinessEntityDetailsQuery["businessEntityById"]["storageFacilities"]
  >;
}

export const defaultShipmentNote: ShipmentNoteInput = {
  content: "",
  receiver: null,
  shipper: null,
  accessLevel: Object.values(NoteAccessLevel),
};
export const defaultShipmentDocument: ShipmentDocumentInputData = {
  _id: uuid(),
  associatedCharge: "",
  isBillable: false,
  name: "",
  type: "",
  url: "",
  file: new File([], ""),
  receiver: null,
  shipper: null,
  accessLevel: Object.values(NoteAccessLevel),
};

export const initialShipment: NewShipmentInputData = {
  id: "",
  assignedCarriers: [],
  constraints: [],
  createdBy: "",
  customer: "",
  documents: [],
  notes: [],
  shipmentLocations: [defaultShipper, defaultReceiver],
  charges: [],
  billOfLadingNumber: null,
  postOfficeNumber: null,
  trailerType: TrailerType.Conestoga,
  loadType: LoadType.FullTruckLoad,
  date: null,
  predefinedRecurrence: PredefinedRecurrence.ONE_TIME,
  commodityType: ShipmentCommodityType.Dry,
};

const initialState: ShipmentState = {
  shipment: initialShipment,
  isCommodityDriven: false,
  goodProfiles: [],
  storageFacilitiesByReceiverId: {},
};

const shipmentSlice = createSlice({
  name: "shipper",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<string>) => {
      state.shipment.customer = action.payload;
    },
    addShipper: (state) => {
      state.shipment.shipmentLocations.push({
        ...defaultShipper,
        _id: uuid(),
        shippedGoods: [{ ...defaultGood, _id: uuid() }],
      });
    },
    addReceiver: (state) => {
      state.shipment.shipmentLocations.push({
        ...defaultReceiver,
        _id: uuid(),
      });
    },
    removeShipperOrReceiver: (state, action: PayloadAction<string>) => {
      const removedShipmentLocation = state.shipment.shipmentLocations.find(
        (shipmentLocation) => shipmentLocation._id === action.payload
      );

      state.shipment.shipmentLocations =
        state.shipment.shipmentLocations.filter(
          (shipmentLocation) => shipmentLocation._id !== action.payload
        );

      const goodIdsToClean = removedShipmentLocation?.shippedGoods.map(
        (shippedGood) => shippedGood._id
      );

      state.shipment.shipmentLocations.forEach((shipmentLocation) => {
        shipmentLocation.receivedGoods = shipmentLocation.receivedGoods.filter(
          (receivedGood) => !goodIdsToClean?.includes(receivedGood.goodId)
        );
      });
    },
    editShipmentLocationInput: (
      state,
      action: PayloadAction<{
        location: ShipmentLocationInputData;
      }>
    ) => {
      const index: number = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) =>
          shipmentLocation._id === action.payload.location._id
      );
      state.shipment.shipmentLocations[index] = action.payload.location;

      if (
        state.isCommodityDriven &&
        action.payload.location.locationType === ShipmentLocationType.DropOff
      ) {
        const receiverLocation = action.payload.location;
        const { goodProfiles } = state;

        const currentShipperLocation = findShipperLocationForReceiverLocation(
          receiverLocation,
          state.shipment.shipmentLocations
        );

        if (currentShipperLocation) {
          currentShipperLocation.shippedGoods =
            receiverLocation.receivedGoods.map((rg) => {
              const storageFacilities = receiverLocation.receiver
                ? state.storageFacilitiesByReceiverId[
                    receiverLocation.receiver
                  ] || []
                : [];
              const storageFacility = storageFacilities.find(
                (sf) =>
                  sf.commodityId === rg.goodProfileId ||
                  sf.commodityId === rg.goodId
              );
              return {
                ...shippedGoodFromReceivedGood(rg, goodProfiles),
                supplierId: storageFacility?.defaultSupplierId || null,
              };
            });

          return;
        }
      }
    },
    selectDefaultShipperForLocation: (
      state,
      action: PayloadAction<{
        locationId: string;
        businessEntity: GetBusinessEntityDetailsQuery["businessEntityById"];
      }>
    ) => {
      const receiverLocation = state.shipment.shipmentLocations.find(
        (location) => location._id === action.payload.locationId
      );

      if (!receiverLocation) {
        return;
      }
      receiverLocation.receivedGoods = [];
      if (
        state.isCommodityDriven &&
        action.payload.businessEntity?.defaultShipperId
      ) {
        const { goodProfiles } = state;
        const { businessEntity } = action.payload;
        const currentShipperLocation = findShipperLocationForReceiverLocation(
          receiverLocation,
          state.shipment.shipmentLocations
        );

        state.shipment.shipmentLocations =
          state.shipment.shipmentLocations.filter(
            (location) => location._id !== currentShipperLocation?._id
          );

        if (!state.isCommodityDriven) {
          return;
        }

        if (businessEntity.storageFacilities) {
          state.storageFacilitiesByReceiverId[businessEntity._id] =
            businessEntity.storageFacilities;
        }

        state.shipment.shipmentLocations =
          state.shipment.shipmentLocations.concat({
            ...defaultShipper,
            shipper: businessEntity.defaultShipperId,
            name: businessEntity.defaultShipper?.name,
            location: {
              latitude:
                businessEntity.defaultShipper?.address.coordinates.latitude ||
                0,
              longitude:
                businessEntity.defaultShipper?.address.coordinates.longitude ||
                0,
            },
            addressLabel: businessEntity.defaultShipper?.address.label,
            addressTimezone: businessEntity.defaultShipper?.addressTimezone,
            shippedGoods: receiverLocation.receivedGoods.map((rg) => {
              const storageFacility = businessEntity.storageFacilities?.find(
                (sf) =>
                  sf.commodityId === rg.goodProfileId ||
                  sf.commodityId === rg.goodId
              );
              return {
                ...shippedGoodFromReceivedGood(rg, goodProfiles),
                supplierId: storageFacility?.defaultSupplierId || null,
              };
            }),
            isDefaultFor: receiverLocation._id,
          });
      }
    },
    setShipperGoods: (state, action: PayloadAction<ShippedGoodsPayload>) => {
      const index: number = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) => shipmentLocation._id === action.payload._id
      );
      state.shipment.shipmentLocations[index].shippedGoods =
        action.payload.goods;
      if (state.isCommodityDriven) {
        return;
      }
      const removedGoodIds = state.shipment.shipmentLocations[
        index
      ].shippedGoods
        .filter(
          (shippedGood) =>
            !action.payload.goods.find((good) => good._id === shippedGood._id)
        )
        .map((removedGood) => removedGood._id);
      // update receiver goods when some goods are removed from the form
      state.shipment.shipmentLocations.forEach((shipmentLocation) => {
        shipmentLocation.receivedGoods = shipmentLocation.receivedGoods.filter(
          (receivedGood) => !removedGoodIds.includes(receivedGood.goodId)
        );
      });

      // autofill received goods if there is only 1 receiver
      const dropOffShipmentLocations = state.shipment.shipmentLocations.filter(
        (sl) => sl.locationType === ShipmentLocationType.DropOff
      );
      const correspondingReceiverLocation =
        dropOffShipmentLocations.length === 1
          ? dropOffShipmentLocations[0]
          : null;
      if (correspondingReceiverLocation) {
        const pickupLocations = state.shipment.shipmentLocations.filter(
          (sl) => sl.locationType === ShipmentLocationType.Pickup
        );
        const allShippedGoods = flatMap(
          pickupLocations,
          (sl) => sl.shippedGoods
        );
        correspondingReceiverLocation.receivedGoods = allShippedGoods.map(
          (good) => ({
            goodId: good._id,
            quantity: good.quantity,
          })
        );
      }
    },
    setReceiverGoods: (state, action: PayloadAction<ReceivedGoodsPayload>) => {
      const index = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) => shipmentLocation._id === action.payload._id
      );
      if (index === -1) {
        return;
      }
      state.shipment.shipmentLocations[index].receivedGoods =
        action.payload.receivedGoods;
      // This is so the whole form gets rerendered
      // eslint-disable-next-line no-self-assign
      state.shipment = state.shipment;
    },
    editShipment: (state, action: PayloadAction<NewShipmentInputData>) => {
      state.shipment = action.payload;
    },
    resetShipmentForm: (state) => {
      state.shipment = initialShipment;
    },
    setIsCommodityDriven: (state, action: PayloadAction<boolean>) => {
      state.isCommodityDriven = action.payload;
      // Remove the default shipper location if we are commodity driven
      if (action.payload) {
        state.shipment.shipmentLocations =
          state.shipment.shipmentLocations.filter(
            (location) =>
              !(
                location.locationType === ShipmentLocationType.Pickup &&
                !location.shipper
              )
          );
      }
    },
    setCommodityType: (state, action: PayloadAction<ShipmentCommodityType>) => {
      state.shipment.commodityType = action.payload;
      state.shipment.trailerType =
        action.payload === ShipmentCommodityType.Dry
          ? TrailerType.Conestoga
          : TrailerType.Tanker;
    },
    setGoodProfiles: (state, action: PayloadAction<GoodProfile[]>) => {
      state.goodProfiles = action.payload;
    },
    selectBestBuyOptionForGood: (
      state,
      action: PayloadAction<BestBuyOption>
    ) => {
      const { shipment } = state;
      state.shipment = {
        ...state.shipment,
        shipmentLocations: shipment.shipmentLocations.map((sl) => {
          if (sl.locationType === ShipmentLocationType.Pickup) {
            return {
              ...sl,
              shippedGoods: sl.shippedGoods.map((sg) => {
                if (sg.goodProfileId === action.payload.goodProfileId) {
                  return {
                    ...sg,
                    supplierId: action.payload.supplierId,
                    unitPrice: action.payload.unitPrice,
                  };
                }
                return sg;
              }),
              shipper: action.payload.shipperId,
              ...action.payload.pickupLocation,
            };
          }
          return sl;
        }),
      };
    },
  },
});

export const {
  setCustomer,
  addShipper,
  addReceiver,
  editShipmentLocationInput,
  selectDefaultShipperForLocation,
  setShipperGoods,
  editShipment,
  setReceiverGoods,
  resetShipmentForm,
  removeShipperOrReceiver,
  setIsCommodityDriven,
  setCommodityType,
  setGoodProfiles,
  selectBestBuyOptionForGood,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;

const shippedGoodFromReceivedGood = (
  receivedGood: GoodDistributionInput,
  goodProfiles: GoodProfile[]
) => {
  const goodProfile = goodProfiles.find(
    (goodProfile) =>
      goodProfile._id === receivedGood.goodProfileId ||
      goodProfile._id === receivedGood.goodId
  );
  return {
    _id: receivedGood.goodId,
    label: goodProfile?.label || "",
    unit: goodProfile?.unit || GoodUnits.Item,
    weight: goodProfile?.weight || 1,
    quantity: receivedGood.quantity || 1,
    goodProfileId: receivedGood.goodProfileId,
  };
};

const findShipperLocationForReceiverLocation = (
  receiverLocation: ShipmentLocationInputData,
  shipmentLocations: ShipmentLocationInputData[]
) => {
  const shipperLocations = shipmentLocations.filter(
    (location) => location.locationType === ShipmentLocationType.Pickup
  );
  const currentShipperLocation =
    shipperLocations.length === 1
      ? shipperLocations[0]
      : shipperLocations.find((location) =>
          receiverLocation.receivedGoods.find((rg) =>
            location.shippedGoods.some((sg) => sg._id === rg.goodId)
          )
        ) ||
        shipperLocations.find(
          (location) => location.isDefaultFor === receiverLocation._id
        );
  return currentShipperLocation;
};
