import "./timeline-resources.css";

import {
  GenerateTripsForShipmentsMutation,
  GetAllDriverShiftsQuery,
  GetAllMaintenanceTasksQuery,
  GetAssignmentAssetsQuery,
  GetBusinessEntityListQuery,
  GetShipmentListForPlanningQuery,
  ShipmentSortCriteria,
  Status,
} from "../../../graphql/generated";
import { Badge, Box, Button, Fab, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import {
  AutoAwesome,
  Close,
  DragHandle,
  FilterAlt,
  Warning,
} from "@mui/icons-material";
import { AssetFiltersValue } from "./AssetFilters";
import { LoadFiltersValue } from "./LoadFilters";
import PlanningGrid from "./PlanningGrid";
import PlanningCalendar from "./PlanningCalendar";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { debounce, isEmpty, omitBy } from "lodash";
import AssetFiltersModal from "./AssetFiltersModal";
import { useTranslation } from "react-i18next";
import AutoPlanModalContainer from "./AutoPlanModal";

export type Assignment = {
  shipmentId: string;
  driverId: string;
  startTime: Date;
};

export type AugmentedShipment =
  GetShipmentListForPlanningQuery["shipments"]["data"][0] & {
    originState: string | null;
    destinationState: string | null;
    originCity: string | null;
    destinationCity: string | null;
    earliestPickup: Date;
    latestPickup: Date;
    earliestDropoff: Date;
    latestDropoff: Date;
    otherShipmentsInTrip: AugmentedShipment[];
    previousPickupTime?: Date | null;
  };

export type AugmentedDriver = GetAssignmentAssetsQuery["drivers"]["data"][0] & {
  associatedTractor: GetAssignmentAssetsQuery["assetLinkings"]["data"][0]["tractor"];
  associatedTrailer: GetAssignmentAssetsQuery["assetLinkings"]["data"][0]["trailer"];
  associatedAdditionalTrailers: GetAssignmentAssetsQuery["assetLinkings"]["data"][0]["additionalTrailers"];
  firstShipmentInPeriod: AugmentedShipment | null;
  lastShipmentInPeriod: AugmentedShipment | null;
};

export type PlanningToolProps = {
  drivers: AugmentedDriver[];
  tractors: GetAssignmentAssetsQuery["tractors"]["data"];
  trailers: GetAssignmentAssetsQuery["trailers"]["data"];
  shipments: AugmentedShipment[];
  filteredShipments: AugmentedShipment[];
  driverShifts: GetAllDriverShiftsQuery["driverShifts"]["data"];
  maintenanceTasks: GetAllMaintenanceTasksQuery["maintenanceTasks"]["data"];
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  assetFilters: AssetFiltersValue;
  loadFilters: LoadFiltersValue;
  driverIdBeingPlanned?: string | null;
  onPeriodChange: (period: { start: Date; end: Date }) => void;
  onAssetSearchChange: (search: string) => void;
  onAssetFiltersChange: (filters: AssetFiltersValue) => void;
  onLoadFiltersChange: (filters: LoadFiltersValue) => void;
  onLoadSearchChange: (search: string) => void;
  onSortChange: (sorCriteria: ShipmentSortCriteria) => void;
  onSortOrderDescChange: (sortDesc: boolean) => void;
  onShipmentsChange: (
    shipments: AugmentedShipment[],
    changedDriver?: string
  ) => void;
  onShipmentsCombine: (shipments: AugmentedShipment[]) => void;
  onFilteredShipmentsChange: (shipments: AugmentedShipment[]) => void;
  onRefresh: (shipmentIds?: string[]) => Promise<void>;
  onSubmit: () => Promise<void>;
  onAutoPlanSubmit: (
    result: GenerateTripsForShipmentsMutation["generateTripsForShipments"]
  ) => void;
  isAutoPlanModalOpen: boolean;
};

const PlanningTool = ({
  onPeriodChange,
  onSortChange,
  onSortOrderDescChange,
  onRefresh,
  isAutoPlanModalOpen,
  ...props
}: PlanningToolProps) => {
  const { t } = useTranslation(["planning", "common"]);
  const [assetFilterDialogOpen, setAssetFilterDialogOpen] = useState(false);

  const [calendarPanelSize, setCalendarPanelSize] = useState(0);
  const setCalendarPanelSizeDebounced = debounce(setCalendarPanelSize, 300);
  const [currentPeriod, setCurrentPeriod] =
    useState<
      | {
          start: Date;
          end: Date;
        }
      | undefined
    >();

  const [selectedShipments, setSelectedShipments] = useState<
    AugmentedShipment[]
  >([]);

  const [autoplanModalOpen, setAutoplanModalOpen] =
    useState(isAutoPlanModalOpen);

  const [showCalendar, setShowCalendar] = useState(true);

  const redrawCalendar = () => {
    setShowCalendar(false);
    setTimeout(() => {
      setShowCalendar(true);
    }, 0);
  };

  const handleRefresh = useCallback(
    async (shipmentIds?: string[]) => {
      await onRefresh(shipmentIds);
      redrawCalendar();
    },
    [onRefresh]
  );

  const headerIndentTemplate = useCallback(() => {
    return (
      <Stack
        direction="row"
        alignItems="start"
        spacing={1}
        sx={{
          p: 1,
        }}
      >
        <Badge
          badgeContent={Object.keys(omitBy(props.assetFilters, isEmpty)).length}
          color="primary"
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              setAssetFilterDialogOpen(true);
            }}
          >
            <FilterAlt />
          </Button>
        </Badge>
      </Stack>
    );
  }, [props.assetFilters]);

  const onShipmentsSelect = useCallback((shipments: AugmentedShipment[]) => {
    setSelectedShipments(shipments);
  }, []);

  const unplannedShipments = useMemo(
    () =>
      props.shipments.filter(
        (shipment) => shipment.status === Status.Unplanned
      ),
    [props.shipments]
  );

  const [unplannedShipmentsToggled, setUnplannedShipmentsToggled] =
    useState(false);

  return (
    <Box sx={{ height: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box>
          <AssetFiltersModal
            open={assetFilterDialogOpen}
            onClose={() => setAssetFilterDialogOpen(false)}
            filters={props.assetFilters}
            onChange={props.onAssetFiltersChange}
            domiciles={props.domiciles}
          />
        </Box>
      </Stack>

      <Box
        sx={{
          height: "calc(100%)",
        }}
      >
        <PanelGroup
          direction="vertical"
          style={{ height: "100%", overflow: "visible" }}
          autoSaveId="PlanningTool::MainPanelGroup"
        >
          <Panel style={{ overflow: "auto" }} defaultSize={40} minSize={10}>
            <PlanningGrid
              {...props}
              filteredShipments={
                unplannedShipmentsToggled
                  ? unplannedShipments
                  : props.filteredShipments
              }
              onShipmentsSelect={onShipmentsSelect}
              onRefresh={handleRefresh}
            />
          </Panel>

          <PanelResizeHandle
            style={{
              height: 24,
              textAlign: "center",
            }}
          >
            <DragHandle />
          </PanelResizeHandle>
          <Panel
            defaultSize={60}
            minSize={10}
            onResize={(size) => {
              setCalendarPanelSizeDebounced(size);
            }}
            style={
              {
                // overflow: "visible",
              }
            }
          >
            <div className="schedule-container" style={{ height: "100%" }}>
              {showCalendar ? (
                <PlanningCalendar
                  {...props}
                  onPeriodChange={(period) => {
                    onPeriodChange(period);
                    setCurrentPeriod(period);
                  }}
                  selectedShipmentId={
                    selectedShipments.length === 1
                      ? selectedShipments[0]._id
                      : null
                  }
                  parentHeight={calendarPanelSize}
                  headerIndentTemplate={headerIndentTemplate}
                  onRefresh={handleRefresh}
                  selectedDate={currentPeriod?.start}
                />
              ) : null}
            </div>
          </Panel>
        </PanelGroup>
      </Box>

      <AutoPlanModalContainer
        isOpen={autoplanModalOpen}
        onClose={() => setAutoplanModalOpen(false)}
        onPlanned={(result) => {
          props.onAutoPlanSubmit(result);
          setAutoplanModalOpen(false);
        }}
      />

      <Button
        variant="contained"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          width: "auto",
          px: 3,
          borderRadius: 16,
        }}
        color="secondary"
        size="small"
        onClick={async () => {
          setAutoplanModalOpen(true);
        }}
      >
        <AutoAwesome /> {t("autoPlanModal.title", "Auto Plan")}
      </Button>

      {unplannedShipments.length ? (
        <Fab
          sx={{
            position: "absolute",
            bottom: 36,
            right: 140,
            width: "auto",
            py: 3,
            px: 3,
            borderRadius: 16,
          }}
          color="warning"
          variant="extended"
          onClick={async () => {
            setUnplannedShipmentsToggled(!unplannedShipmentsToggled);
          }}
        >
          {unplannedShipmentsToggled ? <Close /> : <Warning />}{" "}
          {t("unplannedOrders", "{{count}} Unplanned Orders", {
            count: unplannedShipments.length,
          })}
        </Fab>
      ) : null}

      <Fab
        sx={{
          position: "absolute",
          bottom: 32,
          right: 32,
          width: "auto",
          py: 3,
          px: 3,
          borderRadius: 16,
        }}
        color="primary"
        onClick={async () => {
          await props.onSubmit();
          redrawCalendar();
        }}
      >
        {t("common:save")}
      </Fab>
    </Box>
  );
};

export default PlanningTool;
