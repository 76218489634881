import React, { ReactNode, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ContentPaste, Event, Payments } from "@mui/icons-material";
import trueLogo from "../../../assets/icons/true-logo.svg";
import report from "../../../assets/icons/report.svg";
import loads from "../../../assets/icons/loads.svg";
import tracker from "../../../assets/icons/tracker.svg";
import billing from "../../../assets/icons/billing.svg";
import { Box, Typography } from "@mui/material";
import { useAuth } from "../../../providers/AuthProvider";
import { getCurrentClientAuthConfig } from "../../../providers/keycloak";
import {
  AccountBox,
  CreditCard,
  Extension,
  Logout,
  Settings,
} from "@mui/icons-material";
import RouterLinkComponent from "../RouterLinkComponent";
import { useLocation } from "react-router-dom";
import { isEqual, isString, toArray } from "lodash";
import {
  InvoicingProcess,
  useGetOrganizationSettingsQuery,
  useGetSubscriptionPortalSessionMutation,
} from "../../../graphql/generated";
import keycloak from "../../../providers/keycloak";
import { NAVBAR_HEIGHT } from "../../../views/LayoutRoutes";
import RoleGuard from "../../account/Access/RoleGuard";
import FeatureGuard, { Feature } from "../../account/Access/FeatureGuard";
import { useTranslation } from "react-i18next";
import { appName, companyName } from "../../../config/constants";

export interface MenuItemProps {
  selected?: boolean;
  children?: ReactNode;
  to?: string;
  accessibilityLabel?: string;
  id?: string;
  onClick?: () => void;
}
interface ItemProps {
  label: string;
  name: string;
  key: string;
  linkTo: string;
  icon?: string | ((isSelected: boolean) => ReactNode);
  roles?: string[];
  features?: Feature[];
  disabled?: boolean;
}

const generalMenuItems: Array<ItemProps> = [
  // {
  //   label: "Orders",
  //   name: "orders",
  //   icon: (isSelected) => (
  //     <Store
  //       sx={{
  //         color: isSelected ? "white" : "black",
  //       }}
  //     />
  //   ),
  //   linkTo: "/orders",
  //   roles: ["Carrier Admin", "Manager", "Dispatcher", "Clerical"],
  // },
  {
    label: "Orders",
    name: "orders",
    key: "orders",
    icon: loads,
    linkTo: "/orders",
    roles: ["Carrier Admin", "Manager", "Dispatcher", "Clerical"],
  },
  {
    label: "Live Tracker",
    name: "tracker",
    key: "tracker",
    icon: tracker,
    linkTo: "/tracker",
    roles: ["Carrier Admin", "Manager", "Dispatcher"],
  },
  {
    label: "Load Board",
    name: "load-board",
    key: "loadBoard",
    icon: (isSelected) => (
      <ContentPaste
        sx={{
          color: isSelected ? "white" : "black",
        }}
      />
    ),
    linkTo: "/load-board",
    roles: ["Carrier Admin", "Manager", "Dispatcher"],
  },
  {
    label: "Planning",
    name: "planning",
    key: "planning",
    icon: (isSelected) => (
      <Event
        sx={{
          color: isSelected ? "white" : "black",
        }}
      />
    ),
    linkTo: "/planning",
    roles: ["Carrier Admin", "Manager", "Dispatcher", "Clerical"],
  },
  {
    label: "Billing",
    name: "billing",
    key: "billing",
    icon: billing,
    linkTo: "/billing",
    roles: ["Carrier Admin", "Manager", "Accounting"],
  },
  {
    label: "Settlements",
    name: "settlements",
    key: "settlements",
    icon: (isSelected) => (
      <Payments
        sx={{
          color: isSelected ? "white" : "black",
        }}
      />
    ),
    linkTo: "/settlements",
    roles: ["Carrier Admin", "Manager", "Accounting"],
  },
  {
    label: "Reports",
    name: "reports",
    key: "reports",
    icon: report,
    linkTo: "/reports",
    roles: ["Carrier Admin", "Manager", "Accounting"],
  },
];

const otherMenuItems: Array<ItemProps> = [
  {
    label: "Customers",
    name: "customers",
    key: "customers",
    linkTo: "/customers",
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting"],
  },
  {
    label: "Locations",
    name: "locations",
    key: "locations",
    linkTo: "/business-locations",
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting"],
  },
  {
    label: "Suppliers",
    name: "suppliers",
    key: "suppliers",
    linkTo: "/suppliers",
    roles: ["Carrier Admin", "Manager", "Clerical"],
    features: [Feature.CommodityManagement],
  },
  {
    label: "Commodities",
    name: "commodities",
    key: "commodities",
    linkTo: "/good-profiles",
    roles: ["Carrier Admin", "Manager", "Clerical"],
    features: [Feature.CommodityManagement],
  },
  {
    label: "Tractors",
    name: "tractors",
    key: "tractors",
    linkTo: "/tractors",
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting"],
  },
  {
    label: "Trailers",
    name: "trailers",
    key: "trailers",
    linkTo: "/trailers",
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting"],
  },
  {
    label: "Users",
    name: "users",
    key: "users",
    linkTo: "/users",
    roles: ["Carrier Admin", "HR"],
  },
  {
    label: "Driver Shifts",
    name: "driver-shifts",
    key: "driverShifts",
    linkTo: "/drivers/driver-shifts",
    roles: ["Carrier Admin", "Manager", "Clerical", "Dispatcher"],
    features: [Feature.DriverShifts],
  },
  {
    label: "Relations",
    name: "relations",
    key: "relations",
    linkTo: "/relations",
    features: [Feature.Relationships],
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting", "Dispatcher"],
  },
  {
    label: "Carriers",
    name: "carriers",
    key: "carriers",
    linkTo: "/carriers",
    roles: ["Carrier Admin", "Manager", "Clerical", "Accounting"],
  },
  {
    label: "Accounting",
    name: "accounting",
    key: "accounting",
    linkTo: "/accounting",
    roles: ["Carrier Admin", "Manager", "Accounting"],
    disabled: true,
  },
  {
    label: "TrueCast",
    name: "forecasting",
    key: "forecasting",
    linkTo: "/forecasting",
    roles: ["Carrier Admin", "Manager", "Accounting", "Dispatcher"],
    features: [Feature.CommodityManagement],
  },
];

const Item = ({
  selected,
  children,
  to,
  accessibilityLabel,
  id,
  onClick,
}: MenuItemProps) => {
  return (
    <MenuItem
      selected={selected}
      sx={{
        "&.Mui-selected": (theme) => ({
          backgroundColor: theme.palette.primary.light,
          color: "white",
          borderRadius: 2,

          "&:hover": {
            backgroundColor: theme.palette.primary.light,
            opacity: 0.8,
          },
        }),
      }}
      href={to || ""}
      onClick={onClick}
      component={to?.startsWith("http") ? "a" : RouterLinkComponent}
      aria-label={accessibilityLabel}
      id={id}
    >
      {children}
    </MenuItem>
  );
};

type DrawerMenuProps = {
  onItemClick?: () => void;
};

export default function DrawerMenu({ onItemClick }: DrawerMenuProps) {
  const { t } = useTranslation("common");
  const { userInfo, roles, signOut } = useAuth();
  const getSubscriptionPortalMutation =
    useGetSubscriptionPortalSessionMutation();
  const location = useLocation();
  const [subscriptionPortalUrl, setSubscriptionPortalUrl] = useState("");
  useEffect(() => {
    async function fetchPortalUrl() {
      const subscriptionPortal =
        await getSubscriptionPortalMutation.mutateAsync({});
      setSubscriptionPortalUrl(
        subscriptionPortal.getSubscriptionPortalSession.url
      );
    }
    fetchPortalUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isDriverOnly = isEqual(roles, ["Carrier Driver"]) || isEqual(roles, []);
  const getOrgSettingsQuery = useGetOrganizationSettingsQuery();

  const canAccessBilling =
    !getOrgSettingsQuery.data?.organizationSettings?.invoicing?.processes
      ?.length ||
    getOrgSettingsQuery.data.organizationSettings.invoicing.processes.includes(
      InvoicingProcess.QueueBased
    );
  return (
    <Paper
      sx={{
        p: 2,
        height: "100%",
        boxSizing: "border-box",
        overflow: "auto",
        pb: 12,
      }}
    >
      <MenuList dense>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: NAVBAR_HEIGHT - 24,
          }}
        >
          <Box>
            <img alt="TrueTMS Logo" src={trueLogo} style={{ width: 150 }} />
          </Box>
        </Box>
        <Divider />
        {!isDriverOnly ? (
          <Box
            sx={{ mt: 1 }}
            role="list"
            aria-label="General menu items"
            id="general-menu"
          >
            <Typography variant="h6" id="navigation-header-general">
              <b>{t("general", "General")}</b>
            </Typography>
            {generalMenuItems
              .filter((item) => item.label !== "Billing" || canAccessBilling)
              .map((item, index) => {
                const isSelected = location?.pathname.startsWith(item.linkTo);
                return (
                  <RoleGuard key={index} roles={item.roles}>
                    <Item
                      to={item.linkTo}
                      selected={isSelected}
                      key={index}
                      onClick={() => {
                        onItemClick?.();
                      }}
                      accessibilityLabel={`Navigate to ${item.label}`}
                      id={`navigate-${item.label}`}
                    >
                      <>
                        {item.icon && (
                          <ListItemIcon>
                            {isString(item.icon) ? (
                              <img
                                alt={`Navigate to ${item.label}`}
                                src={item.icon}
                                style={
                                  isSelected
                                    ? {
                                        filter: "invert(1)",
                                      }
                                    : {}
                                }
                              />
                            ) : (
                              item.icon(isSelected)
                            )}
                          </ListItemIcon>
                        )}
                        <Typography variant="subtitle1">
                          {t(item.key, item.label)}
                        </Typography>
                      </>
                    </Item>
                  </RoleGuard>
                );
              })}
          </Box>
        ) : null}
        {!isDriverOnly ? <Divider /> : null}
        {!isDriverOnly ? (
          <Box
            sx={{ mt: 1 }}
            role="list"
            aria-label="Other menu items"
            id="other-menu"
          >
            <Typography variant="h6" id="navigation-header-other">
              <b>{t("others", "Others")}</b>
            </Typography>
            {otherMenuItems.map((item, index) => {
              if (item.disabled) {
                return null;
              }
              return (
                <RoleGuard key={index} roles={item.roles}>
                  <FeatureGuard features={item.features}>
                    <Item
                      to={item.linkTo}
                      selected={location?.pathname.startsWith(item.linkTo)}
                      key={index}
                      onClick={() => {
                        onItemClick?.();
                      }}
                      accessibilityLabel={`Navigate to ${item.label}`}
                      id={`navigate-${item.label}`}
                    >
                      <>
                        {item.icon ? (
                          <ListItemIcon>
                            {isString(item.icon) ? (
                              <img
                                alt={`Icon for ${item.label} menu item`}
                                src={item.icon}
                              />
                            ) : (
                              item.icon(false)
                            )}
                          </ListItemIcon>
                        ) : null}
                        <Typography variant="subtitle1">
                          {(item.key, item.label)}
                        </Typography>
                      </>
                    </Item>
                  </FeatureGuard>
                </RoleGuard>
              );
            })}
          </Box>
        ) : null}
        {!isDriverOnly ? <Divider /> : null}
        {userInfo ? (
          <Box
            sx={{ mt: 1 }}
            role="list"
            aria-label="Account and settings menu items"
            id={`account-menu`}
          >
            <Typography variant="h6" id="navigation-header-account">
              <b>{t("account", "Account")}</b>
            </Typography>
            <Item
              selected={false}
              to={`${keycloak.authServerUrl}/realms/${
                getCurrentClientAuthConfig().realm
              }/account/`}
              accessibilityLabel="Navigate to account settings"
              id={`navigate-account`}
            >
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant="subtitle1"
              >
                {userInfo.name}
                <br />
                <Typography variant="subtitle2" component="span">
                  {toArray(userInfo.organization)[0]?.name}
                </Typography>
              </Typography>
            </Item>
          </Box>
        ) : null}
        <RoleGuard roles={["Carrier Admin"]}>
          {!isDriverOnly && subscriptionPortalUrl ? (
            <Item
              selected={false}
              to={subscriptionPortalUrl}
              accessibilityLabel="Navigate to subscription management"
              id={`navigate-subscription`}
            >
              <ListItemIcon>
                <CreditCard />
              </ListItemIcon>
              <Typography variant="subtitle1">
                {t("manageSubscription", "Manage Subscription")}
              </Typography>
            </Item>
          ) : null}
        </RoleGuard>

        <RoleGuard roles={["Carrier Admin"]}>
          <Item
            selected={location?.pathname.startsWith("/organization-settings")}
            to="/organization-settings"
            accessibilityLabel="Navigate to Organization Settings"
            id={`navigate-org-settings`}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <Typography variant="subtitle1">
              {t("settings", "Settings")}
            </Typography>
          </Item>
        </RoleGuard>

        <RoleGuard roles={["Carrier Admin"]}>
          <Item
            selected={location?.pathname.startsWith("/extensions")}
            to="/extensions"
            accessibilityLabel="Navigate to Extensions"
            id={`navigate-extensions`}
          >
            <ListItemIcon>
              <Extension />
            </ListItemIcon>
            <Typography variant="subtitle1">
              {t("extensions", "Extensions")}
            </Typography>
          </Item>
        </RoleGuard>
        <Item
          selected={false}
          onClick={() => signOut()}
          accessibilityLabel="Logout of TrueTMS"
          id={`navigate-logout`}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <Typography variant="subtitle1">{t("logout", "Logout")}</Typography>
        </Item>
      </MenuList>
      {window.location.host !== "app.truetms.com" && (
        <Typography variant="overline">
          Build {process.env.REACT_APP_BUILD_NUMBER || "N/A"}
        </Typography>
      )}

      <Box
        sx={{
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid rgba(244, 245, 246, 1)",
          mt: 4,
          // pl: isSmDown ? 0 : `${drawerWidth}px`,
        }}
      >
        <Typography textAlign="center" fontSize={14}>
          {t("copyright", {
            date: new Date().getFullYear(),
            appName,
            companyName,
            defaultValue: `Copyright © ${new Date().getFullYear()} ${appName} • A ${companyName}{" "}
          company`,
          })}
        </Typography>
      </Box>
    </Paper>
  );
}
