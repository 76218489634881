import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useAddSupplierContractMutation,
  useEditSupplierContractMutation,
  useSupplierContractByIdQuery,
  useSupplierContractsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import SupplierContractForm from "./SupplierContractForm";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../common/LoadingOverlay";
import { queryClient } from "../../../App";

type SupplierContractFormContainerProps = {
  supplierContractId?: string;
  onSave?: () => void;
};

function SupplierContractFormContainer({
  supplierContractId,
  onSave,
}: SupplierContractFormContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const { id } = useParams();
  const supplierContractByIdQuery = useSupplierContractByIdQuery(
    {
      id: supplierContractId || "",
    },
    {
      enabled: !!supplierContractId,
    }
  );
  const addSupplierContractMutation = useAddSupplierContractMutation();
  const editSupplierContractMutation = useEditSupplierContractMutation();

  const dispatch = useDispatch();

  if (!!supplierContractId && supplierContractByIdQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <SupplierContractForm
      initialSupplierContract={
        supplierContractByIdQuery?.data?.supplierContractById
      }
      saving={
        addSupplierContractMutation.isLoading ||
        editSupplierContractMutation.isLoading
      }
      onSave={async (supplierContractInput) => {
        try {
          if (supplierContractId) {
            await editSupplierContractMutation.mutateAsync({
              id: supplierContractId,
              editSupplierContractData: supplierContractInput,
            });
          } else {
            await addSupplierContractMutation.mutateAsync({
              newSupplierContractData: {
                ...supplierContractInput,
                supplierId: id || "",
              },
            });
          }
          onSave?.();
          queryClient.refetchQueries(useSupplierContractsQuery.getKey());
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditSupplierError", {
                  action: supplierContractId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaultValue: `An error occurred while ${
                    supplierContractId ? "editing" : "creating"
                  } the supplier contract. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
    />
  );
}

export default SupplierContractFormContainer;
